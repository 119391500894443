export const correctionData = [
  {
    name: "Антицеллюлитные, общеоздоровительные обертывания в термоодеяле с водорослями, грязями, различными видами глины и другими косметическими средствами",
    price: "от 1000 ₽",
  },
  {
    name: "VIP-уход (скраб, общий массаж по водорослям, грязям и другими косметическими средствами). Продолжительность процедуры 2 часа",
    price: "от 3700 ₽",
  },
  {
    name: "Скраб + обёртывание + VIP-уход (обертывание с детоксикационными средствами, массаж по водорослям, грязям и другими косметическими средствами). Продолжительность процедуры 3 часа",
    price: "от 4500 ₽",
  },
  {
    name: "Антицеллюлитный массаж (ноги, ягодицы)",
    price: "1500 ₽",
  },
  {
    name: "Антицеллюлитный массаж нижних конечностей (50минут)",
    price: "1700 ₽",
  },
  {
    name: "Корректирующий массаж боковой части туловища (от подмышечной впадины до тазобедренного сустава) (30минут)",
    price: "1000 ₽",
  },
  {
    name: "Общий корректирующий массаж проблемных зон (1час 30 минут)",
    price: "3000 ₽",
  },
  {
    name: "Корректирующий массаж живота",
    price: "700 ₽",
  },
  {
    name: "Моделирующий массаж груди",
    price: "750 ₽",
  },
  {
    name: "Подводный антицеллюлитный массаж",
    price: "600 ₽",
  },
];
