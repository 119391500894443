import { YMaps } from "@pbe/react-yandex-maps";
import { useState } from "react";

import { Overflow } from "./components/Overflow";
import { GoToTop } from "./components/GoToTop";
import { Header } from "./components/Header";
import { Banner } from "./components/Banner";
// import { AboutCreator } from "./components/AboutCreator";
import { MassageTypes } from "./components/MassageType";
import { ClassicPrice } from "./components/ClassicPrice";
import { ChildPrice } from "./components/ChildPrice";
import { Cosmetic } from "./components/Cosmetic";
import { Correction } from "./components/Correction";
import { Specialists } from "./components/Speciallists";
import { Gallery } from "./components/Gallery";
import { Callbacks } from "./components/Callbacks";
import { Socials } from "./components/Socials";
import { Contacts } from "./components/Contacts";
import { Footer } from "./components/Footer";
import { Request } from "./components/Request";
import { useScroll, motion } from "framer-motion";

function App() {
  const [isOpen, setOpen] = useState(false);
  const { scrollYProgress } = useScroll();

  return (
    <>
      <div className="App">
        <motion.div
          style={{
            scaleX: scrollYProgress,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "8px",
            backgroundColor: "#ffdd2d",
            transformOrigin: "0%",
            zIndex: 100,
          }}
        />
        <Header isOpen={isOpen} setOpen={setOpen} />
        <Banner isOpen={isOpen} />

        {/*<AboutCreator />*/}
        <MassageTypes />
        <Request />
        <ClassicPrice />
        <ChildPrice />
        <Cosmetic />
        <Correction />
        <Specialists />
        <Gallery />
        <Callbacks />
        <Socials />
        <YMaps>
          <Contacts />
        </YMaps>
        <Footer />
        <GoToTop />
        {isOpen && <Overflow onClose={() => setOpen(false)} />}
      </div>
    </>
  );
}

export default App;
